<template>
    <div>
        <v-card height="100%">
            <v-toolbar flat dense color="blue grey darken-4 white--text" tile>
                <v-icon dark large>mdi-text-box-plus-outline</v-icon>
                    <h2 class="white--text" style="font-family:'Brush Script MT',cursive;font-name:font-size:20px">Add Record</h2>
                    <v-spacer/>
                <v-icon dark @click="close()" large>mdi-close</v-icon>
        </v-toolbar>
            <v-card-text>
                        <v-row align="center" class="mb-n9">
                            <v-col>
                                <v-textarea 
                                    clearable 
                                    dense 
                                    label="Code Number" 
                                    outlined
                                    v-model="addData.code_number"
                                    >
                                </v-textarea>
                            </v-col>
                        </v-row>
            </v-card-text>
                <v-card-actions>
                        <v-btn v-if="addData.code_number" @click="saveRegister()" class="blue grey darken-4 white--text" tile block >
                            <v-icon>mdi-content-save</v-icon>Save record
                        </v-btn>
                </v-card-actions>
        </v-card>  
    </div>
</template>

<script>
import { bus } from '../main'
import axios from "axios";
import moment from 'moment';
import Swal from "sweetalert2";
    export default {
    data : () => ({
        manualInputData:[],
        addData: {},
        addDialog : false,
        uwCheckBoxFinished : '',
        kansenProposalDuedate : '',
        kansenProposalJoutou : '',
        HacchuFinished : '',
        //variable here
    }),

    methods: {
        close(){
            bus.$emit('CloseDialog' , false)
            this.clear()
        },

        clear(){
            this.addData = {};
        },
    

        async saveRegister() {
                let ArrayCodeNumber = this.addData.code_number.split('\n');
                let NewArr = ArrayCodeNumber.map(code_number => ({ code_number }));

                console.log(NewArr);
                // this.loadHacchuData(NewArr)
                await axios({
                    method: 'post',
                    url: 'http://hrdapps54:2027/api/saveManualInput',
                    data: NewArr,
                    headers: {
                        'x-api-key': this.awsHeaders
                    }
                }) 
                .then((res) => {
                    if (res.data === 'Success') {         
                        this.LoadDataFromManualInput(NewArr)
                        this.loadHacchuData(NewArr)
                    } else {
                        alert('Double Check your data');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert('An error occurred. Please try again.');
                });

        }, // End of Save Data


        async LoadDataFromManualInput(codeNumber) {
                try {
                    const fetchDataPromises = codeNumber.map(async (element) => {
                        // console.log(element);
                        const res = await axios({
                            method: 'get',
                            url: `http://hrdapps54:2027/api/getManualInpuData/${element.code_number}`,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                        });
                    
                            return res.data.map(object =>{
                                return{...object,isManualInput : 1}
                            })
                    });

                    const manualInputData = await Promise.all(fetchDataPromises);
                    // console.log(manualInputData,'LINE 111');
                    const updateResponse = await axios({
                        method: 'post',
                        url: `${this.$BaseUrl}/api/saveManualInputToAws`,
                        data: manualInputData,
                        headers: {
                            'x-api-key': this.awsHeaders
                        }
                    });
                    
                    console.log('Update response:', updateResponse.data);
                } catch (error) {
                    console.error(error,'THIS IS THE ERROR');
                }
        },

        async loadHacchuData(codeNumber) {
                try {
                    
                    const fetchDataPromises = codeNumber.map(async (element) => {
                        // console.log(element);
                        const res = await axios({
                            method: 'get',
                            url: `http://10.168.71.92/vue_api/HacchuInfo.php?code_number=${element.code_number}&kw=1`,
                            // headers: {
                            //     'x-api-key': this.awsHeaders
                            // }
                        });
                        // console.log(res.data);
                        let code ={ code_number : element.code_number}
                        const newData ={...res.data,...code}
                        return newData;
                    });

                    const dataFromApi = await Promise.all(fetchDataPromises);

                    // console.log(dataFromApi[0].HAC657pcCVvalue[0].Details);

                    
                        for (let index = 0; index < dataFromApi.length; index++) {
                            const hacchuData = dataFromApi[index];

                            let objectToUpdate = {
                                code_number:  hacchuData.code_number,
                                wattsDetails : dataFromApi[index].HAC657pcCVvalue[0].Details
                            } 



                            const meterInfoObject = {
                                    MeterInfo_ic_c2 : dataFromApi[index].MeterInfo[index].ic_c2,
                                    MeterInfo_size_cvt1 : dataFromApi[index].MeterInfo[index].size_cvt1,
                                    MeterInfo_ic_c2r2 : dataFromApi[index].MeterInfo[index].ic_c2r2,
                                    MeterInfo_size_c4r2 : dataFromApi[index].MeterInfo[index].size_c4r2,
                                    MeterInfo_ic_c2r3 : dataFromApi[index].MeterInfo[index].ic_c2r3,
                                    MeterInfo_size_c4r3 : dataFromApi[index].MeterInfo[index].size_c4r3,
                                    MeterInfo_ic_c2r4 : dataFromApi[index].MeterInfo[index].ic_c2r4,
                                    MeterInfo_sizeCV_c4r4 : dataFromApi[index].MeterInfo[index].sizeCV_c4r4,
                                    MeterInfo_ic_c2r5 : dataFromApi[index].MeterInfo[index].ic_c2r5,
                                    MeterInfo_size_c4r5 : dataFromApi[index].MeterInfo[index].size_c4r5,
                                    MeterInfo_ic_c2r6 : dataFromApi[index].MeterInfo[index].ic_c2r6,
                                    MeterInfo_size_c4r6 : dataFromApi[index].MeterInfo[index].size_c4r6,
                                    MeterInfo_ic_c1r7 : dataFromApi[index].MeterInfo[index].ic_c1r7,
                                    MeterInfo_size_c4r7 : dataFromApi[index].MeterInfo[index].size_c4r7,
                                    MeterInfo_japNamecv55 : dataFromApi[index].MeterInfo[index].japNamecv55,
                                    MeterInfo_newCv55 : dataFromApi[index].MeterInfo[index].newCv55,
                                }
                            const hacchuObject = {
                                code_number : hacchuData.code_number,
                                Value_c1 : `${hacchuData.HAC657cv1} + ${hacchuData.HAC657cv2}`,
                                wa_c1 : hacchuData.HAC657cv1 + hacchuData.HAC657cv2,
                                lenght_Ksc1 :  hacchuData.HAC657cv1 + hacchuData.HAC657cv2,
                                lpi_6r6 : hacchuData.HAC657pc1,
                                lpi_6r7 : hacchuData.HAC658cv,
                                fl_c7r6 : hacchuData.HAC657pc1,
                                fl_c7r7 : hacchuData.HAC658cv,
                                cc_c1 : hacchuData.KVA,
                                nisetaiCc_c1 : hacchuData.KVA2,
                                sizeCV_c1 : hacchuData.CVT,
                                type : hacchuData.GetMeter,
                                cc_c2 : hacchuData.SolarKW,
                                KyuudenAdaptor : hacchuData.KyuudenAdaptor
                            }

                            const finalData = {...hacchuObject,...meterInfoObject}
                            // console.log(meterInfoObject);
                            console.log(finalData);   
                    
                            
                            await axios({
                                method: 'post',
                                url: `${this.$BaseUrl}/api/insertHacchuData`,
                                data: finalData,
                                headers: {
                                    'x-api-key': this.awsHeaders
                                }
                                })
                                .then(async () => {
                                // Handle successful first request
                                Swal.fire({
                                    toast: true,
                                    position: "center-start",
                                    icon: 'success',
                                    title: "Code number registered",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    color:'green',
                                });

                                // Close the modal or perform any other necessary actions after success

                                // Execute the second Axios request using async/await
                                try {
                                    await axios({
                                    method: 'post',
                                    url: `${this.$BaseUrl}/api/updateWattsDetails`,
                                    data: objectToUpdate,
                                    headers: {
                                        'x-api-key': this.awsHeaders
                                    }
                                    });
                                    this.close()

                                    // Handle successful sec7ond request (optional)
                                    console.log('Second request successful:'); // Example handling
                                } catch (error) {
                                    // Handle errors from the second request
                                    console.error('Error in second request:', error);
                                    // Handle error appropriately, e.g., display an error message to the user
                                }
                                })
                                .catch((error) => {
                                // Handle errors from the first request
                                console.error('Error in first request:', error);
                               
                                });
                                                            
                        }

                       
                       
                

                  
                   
                } catch (error) {
                    console.error(error,'HTIS IS THE ERROR');
                }
        }



  }, //END OF METHODS
    mounted() {
    },
    computed: {
        changeDate(){
            return (str) => {
                if(str != null){
                return moment(str).format('YYYY-MM-DD')
                }
            }
        },
    },
}; //end of mounted
</script>

<style>

</style>